<template>
  <div>
    <!-- Items Loop -->
    <div
      v-for="item in items"
      :key="item.key"
      class="x-scroll-element mx-1 mb-3 p-0"
    >
      <b-link 
        v-show="canView(item)"
        @click="blogLocation(item)"
      >
        <b-card
          :key="item.key"
          no-body
          class="x-scroll-element mx-1 mb-3 p-0 card-blog float-left card-container"
          :img-src="getImageSrc(item.imageURL) ||getImageSrc(item.bannerURL)|| Placeholder"
          :class="item.isPublished ? '' : 'no-published'"
        >
          <div class="d-flex flex-column justify-content-between h-100 p-2">
            <div>
              <h4 v-if="item.title" class="html-text-ellipsis-4">
                {{ item.title[locale] || Object.values(item.title)[0] }}
              </h4>
              <h5 v-if="item.headline" class="html-text-ellipsis-5 mb-1">
                {{ item.headline[locale] || Object.values(item.headline)[0] }}
              </h5>
            </div>
            <b-card-text class="d-flex justify-content-between flex-wrap">
              <div
                class="w-100 text-muted mb-50"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex ">
                    <span class="mr-1">
                      <feather-icon
                        role="button"
                        size="20"
                        :class="
                          item.likedByMember
                            ? 'profile-likes'
                            : 'profile-icon'
                        "
                        icon="HeartIcon"
                        class="mr-25"
                        @click.stop.prevent="toggleLike(item)"
                      />
                      {{ item.totalLikes || 0 }}
                    </span>
                    <span>
                      <feather-icon
                        role="button"
                        size="20"
                        icon="MessageSquareIcon"
                        class="mr-25"
                      />
                      {{ item.commentCount || 0 }}
                    </span>
                  </div>
                  <div>
                    <small v-if="item.date" class="text-muted">
                      {{ timestampToTime(item.date) }}</small>
                  </div>
                </div>
              </div>
            </b-card-text>
          </div>
        </b-card>
      </b-link>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import Vue from 'vue';

export default {
  name: 'BlogCard',

  props: {
    items: Array,
    locale: String,
    staff: Boolean,
    memberRoles: Array,
    user: Object,
    collectiveData: Object,
  },
  data() {
    return {
      Placeholder,
    };
  },
  watch: {

  },
  computed:
  {
    //
  },
  methods: {
    canView(item) {
      return item.isPublished
        // || this.user.key === item.poweredBy?.key
        || this.staff
        || checkPermissions('view', 'blog', this.memberRoles, this.collectiveData);
    },
    canCreate() {
      return this.staff
          || checkPermissions('create', 'blog', this.memberRoles, this.collectiveData);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'blogs',
        morphType: 'blogs',
        key: item?.key,
      });
    },
    blogLocation(item) {
      Vue.$cookies.set('type', item.slug != null ? 'slug' : 'key');
      return this.$router.push({ name: 'blog', params: { id: item.slug || item.key } });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-blog-container {
  height: 550px;
}
.icon-div {
  position: absolute;
  bottom: 0;
  background-color: white;
  margin: auto;
  padding: 2px;
  padding-bottom: 6px;
}
.no-published {
  filter: grayscale(100%);
  opacity: 0.6;
  border-color: black;
  border-style: dashed !important;
  border-width: 3px !important;
}
</style>
