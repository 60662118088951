<template>
  <div class="blog-list">
    <!-- CUIDADO!!!!! NO BORRAR  -->
    <div ref="target" />

    <b-card>
      <div class="d-flex flex-wrap justify-content-between pt-2 align-items-start">
        <div class="d-flex align-items-center float-left">
          <!-- <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a> -->
          <h3 class="m-0 font-weight-600">
            {{ blogTitle }}
          </h3>
        </div>
        <!-- Search -->
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-lg-none mr-2 my-1 mb-lg-3"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-none d-lg-block mr-2 mb-1 mb-lg-3"
          style="width:450px;"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <div class="float-right ">
          <b-button
            v-if="canAdd || isStaff"
            v-b-modal.modal-create-blog
            class="mr-1"
            variant="primary"
          >
            {{ $t("blogs.create-title") }}
          </b-button>
          <!-- <b-button
            v-if="canAdd || isStaff"
            variant="outline-primary"

            @click="exportCsv"
          >
            <div v-if="!isDownloading">
              {{ $t("backoffice.members.export") }}
            </div>
            <b-spinner v-else small class="spinner" />
          </b-button> -->
        </div>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block text-primary" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0" class="cards-container">
        <blog-card
          :items="items"
          :locale="currentLocale"
          :staff="isStaff"
          :member-roles="loggedMemberRoles"
          :user="loggedUser"
          :collective-data="collective"
        />

        <!-- Load More items -->
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="blogPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Create Blog -->
    <b-modal
      id="modal-create-blog"
      :title="$t('blogs.create-title')"
      centered
      :size="isMobile?'xl':'lg'"
      hide-footer
    >
      <b-form @submit.stop.prevent="handleCreateItem">
        <b-form-group>
          <label for="name">
            {{ $t("form-create-item.title") }}
          </label>
          <b-form-input
            id="name"
            v-model="formInput.name"
            :placeholder="$t('form.placeholder')"
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="headline">
            {{ $t("form-create-item.headline") }}
          </label>
          <b-form-input
            id="headline"
            v-model="formInput.headline"
            :placeholder="$t('form-create-item.headline-placeholder')"
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="date">
            {{ $t('backoffice.custom-fields.field-type.date') }}
          </label>
          <VueCtkDateTimePicker
            id="date"
            v-model="date"
            :locale="$store.state.locale.currentLocale"
            format="YYYY-MM-DD"
            formatted="ll"
            :label="$t('backoffice.availability.date-no-slots')"
            color="var(--primary)"
            no-button-now
            only-date
            button-color="var(--primary)"
          />
        </b-form-group>
        <b-form-group>
          <label
            class="mt-2"
            for="description"
          >
            {{ $t("form-create-item.description") }}
          </label>
          <quill-editor v-model="formInput.description" />
        </b-form-group>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group 
              :label="$t('form-create-item.thumbnail')" 
              label-for="thumbnail"
            >
              <file-upload
                id="thumbnail"
                v-model="imageURL"
                type="image"
                cancelable
                :placeholder="$t('form-create-item.image')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="imageSrc = null"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Banner" label-for="banner">
              <file-upload
                id="banner"
                v-model="bannerURL"
                type="image"
                cancelable
                :placeholder="$t('form-create-item.image')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="bannerSrc = null"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Publish -->
        <b-form-group>
          <b-form-checkbox
            v-model="isPublished"
            v-b-tooltip.hover
            :title="$t('tooltips.addons.publish')"
            :disabled="isLoading"
            switch
          >
            {{ $t('common.publish') }}
          </b-form-checkbox>
        </b-form-group>

        <b-button
          variant="primary"
          class="float-right mt-2"
          :disabled="isSending"
          type="submit"
        >
          <span
            v-if="isSending"
            class="d-flex "
          >
            <b-spinner
              class="mr-1"
              small
              variant="white"
              label="Loading..."
            />
            {{ $t('form.actions.save') }}
          </span>
          <span v-else>
            {{ $t('form.actions.save') }}
          </span>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { quillEditor } from 'vue-quill-editor';
import BlogPlaceholder from '@/assets/images/placeholders/light/blog.svg';
import FileUpload from '@core/components/files/FileUpload.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import BlogCard from './BlogCard.vue';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'BlogList',

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    BlogCard,
    quillEditor,
    FileUpload,
    LoadingModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      isSending: false,
      loadingNext: false,
      formInput: {},
      formError: null,
      imageURL: null,
      imageSrc: null,
      bannerURL: null,
      bannerSrc: null,
      date: null,
      searchInput: '',
      timer: null,
      isExportModalOpened: false,
      isPublished: true
    };
  },

  computed: {
    blogTitle() {
      const app = Object.values((this.$store.getters.apps?.apps) || {}).find((x) => x.key === 'blogs');

      if (app?.customizationName) {
        return translateTranslationTable(this.currentLocale, app.customizationName);
      }

      if (app?.name) {
        return translateTranslationTable(this.currentLocale, app.name);
      }

      return this.$t('blogs.title');
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'blog',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.blogs;
    },
    items() {
      if (
        !this.itemsData
        || this.itemsData.length === 0
        || !this.itemsData.unpaginated
      ) {
        return [];
      }
      return this.itemsData.unpaginated;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    blogPlaceholder() {
      return BlogPlaceholder;
    },
    isMobile() {
      return window ? window.innerWidth < 576 : false 
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("1 ++++++++++++ --> WATCH!!! BlogList.vue (targetIsVisible): ", newValue)
      if (newValue && !this.items.length) {
        // console.log("2 --> WATCH!!! BlogList.vue (targetIsVisible): ", newValue)
        this.loadInitData()
      }
    },
    itemURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    }
  },
  // Fetch data when component is created!
  // async created() {
  //   this.isLoading = true;
  //   await this.fetchData({ apiCall: true });
  //   this.isLoading = false;
  // },
  
  methods: {
    async loadInitData () {
      await this.fetchData({ apiCall: true });
    },

    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.blogTitle || this.$t('blogs.page-title.blog-list'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    async fetchData({ searchString = '', apiCall = false }) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'blogs',
        page: this.lastLoadedPage,
        forceAPICall: apiCall,
        requestConfig: {
          perPage: 16,
          searchString,
          orderByDate: -1,
          reduced: 1
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData({ apiCall: false });
        this.isLoadingNextPage = false;
      }
    },
    // async exportCsv(item) {
    //   this.isDownloading = true;
    //   const response = await this.$store.dispatch("exportFiles", {
    //     itemType: "blogs/export",
    //     requestConfig: {
    //       ...(item ? { mainTypeIDs: item } : ""),
    //     },
    //   });
    //   setTimeout(this.csv(response), 10000);
    //   this.isDownloading = false;
    // },
    // csv(response) {
    //   const hiddenElement = document.createElement("a");
    //   hiddenElement.href = response.data.file;
    //   hiddenElement.target = "_blank";
    //   hiddenElement.download = "Blogs.csv";
    //   hiddenElement.click();
    // },
    async handleCreateItem() {
      if (!this.formInput.name) {
        return;
      }
      const file = {};
      if (this.imageURL) file.imageURL = this.imageURL;
      if (this.bannerURL) file.bannerURL = this.bannerURL;
      try {
        this.isSending = true;
        const requestConfig = { 
          title: {}, 
          content: {}, 
          headline: {},
          isPublished: true
        };
        const locale = this.$store.state.locale.currentLocale;
        requestConfig.title[locale] = this.formInput.name;
        if (this.date) {
          let datetime = new Date(this.date);
          datetime.setHours(datetime.getHours()+12);  //ponemos la fecha centrada al medio dia
          requestConfig.date = datetime;
        }
        requestConfig.headline[locale] = this.formInput.headline;
        requestConfig.content[locale] = this.formInput.description;
        requestConfig.isPublished = this.isPublished;
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'blogs',
            requestConfig,
          },
          file,
        });
        this.fetchData({ apiCall: true });
        this.formInput = {};
        this.imageURL = null;
        this.bannerURL = null;
        this.isSending = false;
        this.isPublished = true;
        this.$bvModal.hide('modal-create-blog');
        this.notifySuccess(this.$t('success-message.general-success-create'));
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
        this.isSending = false;
      }
    },
    searchItems(page = { page: 1 }) {
      this.lastLoadedPage = page.page;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchData({
          searchString: this.searchInput,
          apiCall: true,
        });
      }, 800);
    },
  },
};
</script>
<style>
.card-container {
  height: 375px;
}
.date-container {
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  width: 100%;
}
.content-container > img {
  width: 100%;
}
.card-img {
  height: 150px !important;
}
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.card-img {
  height: 200px !important;
}
</style>
